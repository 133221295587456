/* bootstrap override so empty cells have no height 
   bootstrap default is min-height:1px 
*/
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    min-height: 0;
}
h1, h2, h3, h4, h5, h6 {
    text-rendering: optimizeLegibility;
    font-weight: 300;
}
.h1, h1 {
    font-size: 3rem;
}
.h2, h2 {
    font-size: 2rem;
}
img {
    max-width: 100%;
    height: auto;
}
.sr-only {
    color: #fff;
}

/* jls navigation */
.navbar {
    border-radius: 0px;
    background-color: #222;
    border-bottom: 6px solid #006341;
    min-height: 66px;
}
.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
}
.navbar>.container-fluid .navbar-brand {
    margin: 0;
    padding-top: 20px;
}
.navbar .icon-bar {
    background: #fff;
}
.navbar .jls-logo {
    float: left;
    margin-top: 15px;
    margin-left: 10px;
}
.navbar a,
.dropdown-menu li a {
    color: #fff;
}
.navbar .navbar-nav a:hover,
.navbar .navbar-nav a:active, 
.navbar .navbar-nav a:focus,
.navbar .navbar-nav .open > a,
.navbar .navbar-nav .open > a:active,
.navbar .navbar-nav .open > a:focus {
    color: #fff;
    background: #333;
}
.jls-nav-container .dropdown-menu {
    border-color: #333;
    background: #333;
}

.jls-nav-container .navbar-nav > li > .dropdown-menu {
    background-color: #333;
}

.navbar .jls-property-tracker a:hover {
    background: none;
}

.navbar-nav>li>a {
    padding: 2rem 1.5rem;
}

@media screen and (min-width: 1300px) {
    .navbar-collapse {
        float: right;
    }
}

/* set jls navigation to responsive for tablet */
@media (max-width: 1300px) {
    .navbar-header {
        float: none;
    }
    .navbar-left, .navbar-right {
        margin: 0;
    }
    .navbar-toggle {
        margin: 13px 0;
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
        width: 100%;
    }
    .navbar-fixed-top {
        top: 0;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in {
        display:block !important;
    }
    .navbar-collapse.collapse.in {
        overflow-y: auto;
        max-height: calc(100vh - 61px);
    }
    .navbar .jls-property-tracker {
        padding: 0;
    }
}

/* jls page wrapper */
.jls-page-wrapper {
    min-height: 75%;
    padding-top: 66px;
    transition: padding ease .25s;
}

/* jls search mastead */
.jls-search-masthead-container {
    padding: 1rem 0 2rem;
}
.jls-agent-profile {
    padding: 0 0;
}
.jls-agent-profile .jls-agent-image {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 2px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}
@media screen and (min-width: 768px) {
    .jls-agent-profile .jls-agent-image {
        float: right;
    }
}
.jls-agent-profile .jls-agent-contact {
    padding:2rem 0;
    color: #fff;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .jls-agent-profile .jls-agent-contact {
       text-align: left;
    }
}
.jls-agent-profile .jls-agent-name {
    font-size: 2.5rem;
}
.jls-agent-profile .jls-agent-title {
    font-size: 2rem;
    font-weight: 100;
}
.jls-search-button.btn-success {
    background: #51ab51;
    border-color: #51ab51;
    box-shadow: none;
}
.jls-search-button.btn-success:hover {
    background: #4da34d;
    border-color: #4da34d;
    outline: none;
}
.jls-search-button.btn-success:focus {
    background: #4a934a;
    border-color: #4a934a;
    outline: none;
}
.jls-search-toggle {
    color:#51ab51;
}

/* jls brand mastead */
.jls-brand-masthead-container {
    height: 200px;
}
@media (min-width:768px) {
    .jls-brand-masthead-container {
        height: 480px;
    }
}
.jls-brand-masthead-image,
.jls-search-masthead-image,
.jls-image-url {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}
.jls-brand-masthead-overlay,
.jls-search-masthead-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+0,000000+100&0+0,0.5+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,.25) 0%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,.25) 0%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,.25) 0%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
}
.jls-brand-masthead-profile {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

@media (max-width: 768px) {
    .col-xs-6.col-md-4.jls-masthead-image {
        display: none;
    }
    .col-xs-6.col-md-4.jls-contact-column {
        width: 100%;
    }
}

input#jls-search-input {
    color: #555 !important;
}

/* jls-vertical-fill used for the brand masthead 
   to set the height:100% of the jls-agent-image container 
*/
.jls-vertical-fill,
.jls-vertical-fill > div {
    height: 100%;
}
/* jls-contact-details */
.jls-contact-details {
    padding: 5% 0;
    text-align: center;
    color: #fff;
    font-size: 50%;
}
.jls-contact-details h1,
.jls-contact-details h2,
.jls-contact-details h3,
.jls-contact-details h4,
.jls-contact-details h5{
    margin-top: .5em;
    margin-bottom: 0;
}
.jls-contact-details h1 {
    font-size: 2.5em;
}
.jls-contact-details h2 {
    font-size: 2.75em;
}
.jls-contact-details h3 {
    font-size: 1.65em;
}
.jls-contact-details h4 {
    padding: .5rem;
    font-size: 1.5em;
    font-style: italic;
    font-weight: 100;
    border-top: 1px solid;
    border-bottom: 1px solid;
    display: inline-block;
}
@media (min-width:768px) {
    .jls-contact-details {
        font-size: 90%;
    }

}
@media (min-width:992px) {
    .jls-contact-details {
        font-size: 100%;
    }

}

.jls-contact-details .jls-contact-circle {
    display: none;
}
@media (min-width:768px) {
    .jls-contact-details .jls-contact-circle {
        position: relative;
        margin: 0 auto;
        border: 2px solid;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: table;
    }
}
.jls-contact-details .jls-contact-circle span {
    font-size: 50px;
    display: table-cell;
    vertical-align: middle;
}

/* jls brand mastead awards */
.jls-awards-container {
    margin: 0;
    padding: 2rem 0;
    list-style: none;
    float: right;
}
.jls-awards-container .jls-award {
    margin: 0 auto;
    width: 125px;
    padding: 1.5rem;
}

/* jls callout buttons */
.jls-callout-button-outer {
    margin: 2rem 0;
    position: relative;
    text-align: center;
}
.jls-callout-button-outer .jls-callout-button {
    position: relative;
    margin: 0 auto;
    width: 100px;
}
.jls-callout-button-outer .jls-callout-button-circle {
    position: relative;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 1px solid;
    border-radius: 50%;
    box-shadow: 0px 0px 20px rgba(0,0,0,.15);
    color: #666;
    background: #fff;
    overflow: hidden;
    z-index: 1;
}
.jls-callout-button-outer .jls-callout-button-circle .image {
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}
.jls-callout-button-outer .jls-callout-button-circle .property-tracker {
    background-image: url(/Images/home-photo-2.jpg);
}
.jls-callout-button-outer .jls-callout-button-circle .map {
    background-image: url(/Images/interactive-map.jpg);
}
.jls-callout-button-outer .jls-callout-button-circle .mobile-app {
    background-image: url(/Images/mobilePhones_v1.png);
}
.jls-callout-button-outer .find-agent .image {
    background-image: url(/Images/find-agent.jpg);
}
.jls-callout-button-outer .search-homes .image {
    background-image: url(/Images/search-homes.jpg);
}
.jls-callout-button-outer .join-us .image {
    background-image: url(/Images/join-us.jpg);
}
.jls-callout-button-outer .jls-callout-button-circle + span {
    position: absolute;
    top: 50px;
    left: 60px;
    width: 50px;
    height: 50px;
    font-size: 33px;
    color: #fff;
    z-index: 1;
    background: #808080;
    border-radius: 50%;
    border: 2px solid;
}
.jls-callout-button-outer .jls-callout-button-circle span {
    font-size: 50px;
    padding: 1rem;
    display: block;
}
.jls-callout-button-outer h2 {
    position: relative;
    margin: 0 auto;
    padding: 1.5rem 0;
    font-size: 2.5rem;
    color: initial;
    width: 100%;
}
.jls-callout-button-outer p:not(.image) {
    font-size: 1.75rem;
    font-weight: 300;
    opacity: .75;
    color: initial;
}
.jls-callout-button-outer a {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}
.jls-callout-button-outer sup {
    top: -1rem;
    font-size: 50%;
}

/* office callout buttons */
.find-agent .fa,
.search-homes .fa,
.join-us .fa {
    position: relative;
    top: -2px;
    font-size: 30px;
}

/* jls home search */
@media screen and (min-width: 768px) {
    .jls-search-masthead-container .search-width {
        width: 70%;
    }
}
@media screen and (min-width: 992px) {
    .jls-search-masthead-container .search-width {
        width: 40%;
    }
}
.jls-search-heading {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: #fff;
    font-weight: 100;
}

/* jls-cell-1 jls-image 
   set any content type:image placed in jls-cell-1 to width:100%
*/
.jls-image-container {
    min-height: 350px;
}
.jls-cell-1 .jls-image-container {
        margin: 0 -15px;
    }
@media screen and (min-width: 768px) {
    .jls-cell-1 .jls-image-container {
        height: 380px;
        overflow: hidden;
    }
}
/* jls-cell:not(1) jls-image 
   set any content type:image placed in jls-cell:not(1) to normal
*/
[class*="jls-cell"]:not(.jls-cell-1) .jls-image-container {
    position: relative;
    margin: 15px 0 0;
}

/* jls feature listings */
.jls-featured-listings-container {
    margin-bottom: 4rem;
}
.jls-featured-listings-header-bar {
    padding: 1rem;
    display: table;
    width: 100%;
}
.jls-featured-listings-header {
    display: table-cell;
    vertical-align: top;
    text-align: left;
}
.jls-featured-listings-sort {
    padding: 1rem 0;
    display: table-cell;
    vertical-align: top;
    text-align: right;
}
.jls-featured-listings-header-bar h2 {
    margin: .5rem 0 0;
    padding: 0;
}
.jls-featured-listings-header-bar select {
    margin: 0 1rem;
    padding: .5rem 1rem;
    color: #000;
    border-radius: 3px;
}
.jls-featured-listings-grid {
    margin-bottom: 2rem;
    border-color: transparent;
    -moz-box-shadow: 0 0 0;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}
.jls-listing-container {
    margin: 0 0;
    padding: 0 0;
}
.jls-listing {
    position: relative;
    margin: .5rem;
}
.jls-listing-content {
    position: relative;
    height: 225px;
}
@media screen and (min-width: 1170px){
    .jls-listing-content {
        height: 250px;
    }
}
.jls-listing-content .jls-listing-top-bar,
.jls-listing-content .jls-listing-bottom-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    background: rgba(0,0,0,.5);
    height: 30px;
    z-index: 1;
}
.jls-listing-content .jls-listing-bottom-bar {
    top: auto;
    bottom: 0;
}
.jls-listing-content .jls-listing-status,
.jls-listing-content .jls-listing-location,
.jls-listing-content .jls-listing-price,
.jls-listing-content .jls-listing-like {
    position: absolute;
}
.jls-listing-content .jls-listing-status {
    top: 5px;
    left: 5px;
}
.jls-listing-content .jls-listing-location {
    top: 5px;
    right: 5px;
}
.jls-listing-content .jls-listing-price {
    color: #fff;
    bottom: 5px;
    left: 5px;
}
.jls-listing-content .jls-listing-like {
    bottom: 5px;
    right: 5px;
}
.jls-listing-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;     
}
.jls-listing-image div {
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
}
.jls-listing .jls-listing-specs {
    margin-top: 1px;
    padding: .75rem;
    min-height: 35px;
    color: initial;
    background: #fff;
}
.jls-map-search {
    text-align: center;
}
/* jls feature listings pager */
.jls-featured-listings-container .pager {
    padding: 3rem 0;
    border: none;
    border-top: 1px solid #ccc;
    background: none;
    box-shadow: none;
}
.jls-featured-listings-container .pager li>a,
.jls-featured-listings-container .pager li>span {
    padding: inherit;
    border-color: #ccc;
    border-right: 0;
    color: #444;
}
.jls-featured-listings-container .pager li>a:hover,
.jls-featured-listings-container .pager li>a:active {
    color: #444;
}
.jls-featured-listings-container .pager [class*="selected"] {
    background-color: #006341;
    border-color: #006341;
    color: #fff;
}
.pager.k-pager-wrap .k-icon {
    font-size: 100%;
    text-indent: -1000px;
}
.pager.k-pager-wrap .k-link:link {
    color: #000;
    font-weight: 400;
}

/* jls html text */
.jls-html-text {
    padding: 15px 0;
}
.jls-html-text h1,
.jls-html-text h2,
.jls-html-text h3,
.jls-html-text h4,
.jls-html-text h5 {
    color: inherit;
    font-weight: 400;
}

/* jls ask me */
.jls-ask-me {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
}
.jls-ask-me h1 {
    margin: 2rem 0;
    text-align: center;
}
.jls-ask-me p {
    margin: 5px 0;
    font-size: 1.5rem;
}
.jls-ask-me .jls-agent {
    margin-bottom: 1rem;
    padding: 0;
}
.jls-ask-me .jls-agent-name {
    color: #4cae4c;
    margin-top: 0;
}
.jls-ask-me .jls-agent-contact a {
    font-size: 1.5rem;
    display: block;
}
.jls-ask-me .jls-social-icons ul {
    margin-top: .5rem;
    padding: 0;
}
.jls-ask-me .jls-social-icons li {
    margin-right: 3px;
    padding: 3px;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    background: #e1e1e1;
    list-style: none;
    text-align: center;
    display: inline-block;
}

/* jls-quick-search */
.jls-quick-search {
    padding: 0 0 3rem;
}
.jls-quick-search-header {
   margin: 2rem;
   font-size: 2.5rem;
   text-align: center;
}
.jls-quick-search-state {
   margin-bottom: 4rem;
   font-size: 2rem;
   text-align: center;
}
.jls-quick-search-locations ul {
    list-style: none;
}
.jls-quick-search-locations ul li {
    display: inline-block;
    padding: 4px 8px;
    margin: 1px;
    width: 10em;
}
.jls-quick-search-locations a {
    display: block;
}

/* buying a house */
.buying-a-house {
    color: #333;
}
.buying-a-house div {
    text-align: center;
    font-size: 2em;
    margin: 40px 0;
    font-weight: 400;
}
/* selling a house */
.selling-a-house {
    color: #333;
}
.selling-a-house h4 {
    font-size: 2.65rem;
    font-weight: 300;
}
.selling-a-house div {
    text-align: center;
    font-size: 2em;
    margin: 40px 0;
    font-weight: 400;
}

/* jls tabs and accordian content */
.jls-tab-container .jls-intro {
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;
}
.jls-tab-container .jls-intro h4 {
    font-size: 2.65rem;
    font-weight: 300;
    line-height: 1.3em;
}
.jls-tab-container .jls-intro h5 {
    padding-top: 2rem;
    font-size: 2.75rem;
    font-weight: 400;
    line-height: 1.3em;
}
/* jls static tabular and accordian content
   display accordion behavior and add adjustments to some layout classes 
*/
@media screen and (max-width: 767px) {
    .jls-hide-767 {
        display: none;
    }
    .jls-show-767 {
        margin-bottom: 2rem;
        display: block;
        overflow: hidden;
    }
    div.row.jls-tab-pane-heading-cont {
        border-bottom: 0;
    }
    .jls-tab-pane-heading {
        text-align: center;
    }
}
/* jls static tabular and accordian content
   hide accordion behavior 
*/
@media screen and (min-width: 767px) {
    .jls-hide-767 {
        display: block;
    }
    .jls-show-767 {
        display: none;
    }
}
.jls-show-767 [class*="jls-tab"] {
    padding: 2rem 0 0;
}
.jls-show-767 img {
    margin: 0 auto;
}
#jls-buyer-tabs {
    padding: 0;
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 200;
    text-align: center;
    letter-spacing: .1em;
    border-radius: 4px 4px 0 0;
    background-color: #eaeaea;
    border: none;
    overflow: hidden;
}
.nav-pills>li>a {
    border-radius: 4px 4px 0 0;
}
.jls-tab-panel-wrapper {
    margin-bottom: 30px;
    padding: 20px 15px;
    border: 1px solid rgb(230, 230, 230);
}
.jls-tab-pane-heading {
    font-size: 2em;
    font-weight: 300;
    color: #006341;
}
.jls-tab-pane-paragraph {
    margin-top: 15px;
    font-size: 1.2em;
    line-height: 1.6em;
}
.jls-show-767 .jls-tab-pane-paragraph {
     padding: 1rem 2rem;
}
#jls-buyer-tabs a {
    font-size: 1.25em;
    font-weight: 300;
    padding: 15px 0 12px 0;
    letter-spacing: .02em;
}
.jls-hollow-btn {
    color: rgba(255, 255, 255, 0.65);
    font-size: 1.2em;
    margin: 0 auto;
    text-align: center;
    border-radius: 3px;
    max-width: 240px;
    display: block;
}
.jls-buyer-tab-content.jls-hollow-btn {
    margin-top: 40px;
    margin-bottom: 40px;
}
.jls-buyer-tab-content a.jls-hollow-link {
    text-decoration: none;
    color: #fff;
    background-color: #006341;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #fff;
    display: block;
    font-weight: 300;
    font-size: 1.2em;
    padding: 10px 20px;
}
.jls-buyer-tab-content a.jls-hollow-link:hover {
  text-decoration: none;
  color: #006341;
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #006341;
  display: block;
  font-weight: 400;
}
sub, sup {
    top: -.5em;
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
.jls-property-tracker-header {
    position: relative;
    top: -2rem;
    margin: 0 auto;
    width: 265px; 
}
.jls-hide-767 .jls-modal-title,
.jls-show-767 .jls-modal-title {
    font-size: 2.5rem;
    float: left;
    z-index: 300;
    vertical-align: middle;
    left: -5px;
    position: relative;
    text-shadow: 1px 3px 6px #fff;
    font-weight: 300;
    top: 4px;
}
.jls-hide-767 .jls-pt-icon-bg,
.jls-show-767 .jls-pt-icon-bg {
    margin-right: 1rem;
    width: 50px;
    height: 50px;
    float: left;
    z-index: 200;
    opacity: .7;
}
.jls-property-tracker-tab-bg {
    margin: 0;
    padding-top: 15px;
    background-image: url(/Images/propertyTracker_tabImage.png);
    background-size: cover;
    background-position: center right;
    min-height: 350px;
    background-repeat: no-repeat;
    text-align: center;
}
.jls-mobile-app-tab-bg {
    background-image: url(/Images/mobile_app_tab_img.jpg);
    background-size: cover;
    background-position: top right;
    min-height: 380px;
    background-repeat: no-repeat;
}
.jls-tab-pane-italic-heading {
    font-family: serif;
    font-style: italic;
    font-size: 1.15em;
}
a:active div span.jls-step-number, 
a:focus div span.jls-step-number, 
li[class='active'] a div span.jls-step-number {
    color: #dc9103;
}
a:active div.jls-step-number-cont, 
a:focus div.jls-step-number-cont, 
li[class='active'] a div.jls-step-number-cont {
    background-color: #fff;
}
#jls-buyer-tabs .nav > li > a:focus, 
#jls-buyer-tabs .nav > li > a:hover, 
#jls-buyer-tabs .nav > li > a:active {
    text-decoration: none;
    background-color: none;
    border-bottom: 4px solid #006341;
}
#jls-buyer-tabs .nav-pills > li.active > a, 
#jls-buyer-tabs .nav-pills > li.active > a:focus,
#jls-buyer-tabs .nav-pills > li.active > a:hover,
#jls-buyer-tabs .nav-pills > li > a, 
#jls-buyer-tabs .nav-pills > li > a:focus, 
#jls-buyer-tabs .nav-pills > li > a:hover {
    color: #111;
    background: none;
    white-space: nowrap;
}
#jls-buyer-tabs .nav-pills > li.active > a, 
#jls-buyer-tabs .nav-pills > li.active > a:focus, 
#jls-buyer-tabs .nav-pills > li.active > a:hover {
    /*font-weight: 500;*/
    border-bottom: 4px solid #006341;
    background-color: #f3f3f3;
}
#jls-buyer-tabs .nav-pills > li:nth-last-child(n+2) {
    border-right: 2px solid #fff;
}

.jls-mobile-tab-btn {
    margin-top: 10px;
    margin-bottom: 4px;
}
.jls-mobile-tab-btn a.jls-mobile-tab-link {
    text-decoration: none;
    color: #222;
    background-color: #eaeaea;
    text-align: center;
    border: 1px solid #fff;
    display: block;
    font-weight: 300;
    font-size: 1.6em;
    padding: 10px 20px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-radius: 3px;
}
a.jls-mobile-tab-link.collapsed::after {
    content: "\e080";
}
a.jls-mobile-tab-link::after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    color: grey;
    position: absolute;
    right: 24px;
    margin-top: 4px;
    font-size: .8em;
}
jls-mobile-tab-btn a.jls-mobile-tab-link:hover {
    text-decoration: none;
    color: #111;
    background-color: #f3f3f3;
    text-align: center;
    display: block;
}
.jls-mobile-tab-btn a.jls-mobile-tab-link[aria-expanded="true"] {
    border-radius: 3px;
}
.jls-tab-container [class*="jls-tab-copy-"]{
    padding: 2rem;
}

/* embed responsive */
.embed-responsive {
    margin: 2rem 0;
}

.jls-listing-details .jls-disclaimer {
    padding: 1rem 0;    
}


/* about me */
.about-me h2 {
    margin-bottom: 1rem;
    padding-bottom:1rem;
    display:inline-block;
    color: #006341;
    border-bottom: 1px solid rgba(0,99,65,.25);
}
.about-me h4 {
    margin-top: 2rem;
    color: inherit;
    line-height: 1.2;
    font-size: 2.5rem;
}
@media (min-width: 992px) {
    .about-me h4 {
        margin-top: 4rem;
    }
}

/* embed responsive */
.embed-responsive {
    margin: 15px 0;
}

/* footer */
.jls-sliding-footer-tab {
    display: none;
}

/* owl carousel */
.owl-carousel .item {
    height: 15rem;
    background: #4DC7A0;
    padding: 1rem;
    display: block;
}
ul.owl-carousel {
    margin: 0;
    padding: 0;
}